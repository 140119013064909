import React from "react";
import * as FileSaver from "file-saver";
import "./Courses.css"; // Updated CSS file with new styles
import { Link } from "react-router-dom";

const Courses: React.FC = () => {
  const coursesTitleStyles: React.CSSProperties = {
    backgroundImage: "url('/C3.jpg')", // Assumes C3.jpg is in the public folder
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "0 56px 30px #000",
    color: "#faebd7",
    fontWeight: "bolder",
    marginBottom: "100px",
    marginTop: "60px",
    padding: "16%",
    textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
  };

  return (
    <div className="container coursesContainer" id="courses">
      <h2 className="coursesTitle" style={coursesTitleStyles}>
        Welcome to our Academy's Courses Page!
      </h2>

      {/* New Academy Overview Section */}
      <div className="academy-overview">
        <h2>Academy Overview</h2>
        <p>
          The Academy offers an intensive eight&#8209;week course covering
          critical topics in micro&#8209;soldering, designed for both beginners
          and experienced technicians. Our curriculum focuses primarily on
          interposer board repairs, as well as straight board repairs for iPhone
          models 7 through 16 Pro Max. We address common issues such as power
          faults, charging issues, panic errors, audio faults, iTunes errors,
          network&#8209;related problems and many more.
        </p>
        <p>
          Every student is required to complete a two&#8209;week internship at
          our mastercraft workshop, providing hands&#8209;on experience before
          graduation. This brings the total program duration to eight weeks.
          Additionally, we offer discounted tool sales to help you start your
          career immediately.
        </p>
        <p>
          Comprehensive video guides are provided for all modules and remain
          accessible after training. The total tuition fee for the course plus
          internship is &#8358;600,000.
        </p>
        <p>
          For interstate students, affordable accommodation is available,
          including 24&#8209;hour power, room cleaning, and laundry services
          (laundry for personal clothing is not included).
        </p>
        <p>
          Enrollment is exclusively available to technicians with prior repair
          experience. Prospective students must undergo a virtual screening
          process and answer detailed questions regarding their experience and
          technical proficiency.
        </p>
        <p>
          <br />
          <Link className="apply-now-link" to="/apply-now">
            Apply Now
          </Link>{" "}
          <br />
          <span>to start your journey with us!</span>
        </p>
      </div>

      {/* Special Discount Notice */}
      {/* <h2 className="notice-heading">Special Discount</h2>
      <p className="notice-one">
        <span>N100,000 off when enrolling in both sections.</span>
        <br />
        Enroll now by{" "}
        <span className="downloadForm" onClick={downloadForm}>
          CLICKING HERE
        </span>{" "}
        to download our course registration form.
        <br />
        Attach and send the completed form to{" "}
        <a href="mailto:Goldencraftsacademyltd@gmail.com">
          Goldencraftsacademyltd@gmail.com
        </a>
        . Thank you!
      </p>

      <div className="discount-notice">
        <h2 className="explore">Explore Mobile Phone Repair</h2>
        <p>
          Beginners covers foundational concepts and issue diagnosis, while
          Intermediate focuses on advanced repairs and troubleshooting.
        </p>
      </div> */}

      {/* Special Discount Notice */}
      <div className="course-summary-details-container">
        {/* Course Details: Beginners and Intermediate */}
        <div className="row">
          <h5 className="courseTitle">Course Summary</h5>

          {/* Course Section */}
          <div
          // className="col-md-6"
          >
            <div className="beginners">
              <div className="courseHeadings">
                <h5>Cost: &#8358;600,000</h5>
                <h5>
                  <h5>Duration: 8 weeks total (2 weeks internship)</h5>
                </h5>

                <h5>Phone-type: iPhone 7&#8209;16 pro max</h5>
                <h5 className="duration">
                  Time: Monday - Friday (10:00 AM - 3:00 PM)
                </h5>
              </div>
              <ul className="ulParent">
                <li>
                  Upon completion of the training, you'll have mastered the
                  following skills:
                </li>
                <ul>
                  <li>iTunes error troubleshooting</li>
                  <li>Panic error troubleshooting</li>
                  <li>Data recovery techniques</li>
                  <li>Memory upgrade procedures</li>
                  <li>FPC replacement</li>
                  <li>Running jumper with fly line wire</li>
                  <li>Understanding ZXW (Zillion X works), JCID</li>
                  <li>
                    Identification and function analysis of all ICs on the
                    motherboard
                  </li>
                  <li>Troubleshooting and fault finding</li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
