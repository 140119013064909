import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "./Homepage.css";
import { Link } from "react-router-dom";

const Homepage: React.FC = () => {
  const navigate = useNavigate();
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      // For mobile: width <= 480px or for tablet: height between 1024px and 1368px
      if (width <= 480 || (height >= 1024 && height <= 1368)) {
        setIsMobileOrTablet(true);
      } else {
        setIsMobileOrTablet(false);
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // Inline style for the hero container (top section)
  const homeStyle: React.CSSProperties = {
    paddingRight: "2.67vw",
    paddingBottom: "8vh",
    paddingLeft: "2.67vw",
    color: "white",
  };

  return (
    <div className="Homepage-container">
      {/* Hero / Carousel Section */}
      <div className="hero-section" id="home" style={homeStyle}>
        <h1 className="company-title">
          <p className="golden">Golden</p>
          <p className="craft">Crafts Academy</p>
        </h1>

        <div className="home-carousel">
          <Carousel controls={false} indicators={true} interval={3000}>
            <Carousel.Item>
              <div className="carousel-inner-container">
                <p className="welcom-carosel">
                  Learn how to fix <br /> mobile phones
                </p>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="carousel-inner-container">
                <p>
                  More practical <br /> less theory
                </p>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="carousel-inner-container">
                <p>
                  We are the best <br /> at what we do
                </p>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="carousel-inner-container">
                <p>
                  What are you <br /> waiting for?
                </p>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

      {/* About Section */}
      <div className="about-section">
        <h2 className="what-we-do">What We Do</h2>
        <p className="what-we-do-text">
          We specialize in teaching micro-soldering techniques for mobile
          electronics. Our comprehensive training ensures that students gain
          in-depth knowledge of board repairs. Whether you’re a beginner,
          intermediate, or a master-level learner, our academy offers a course
          to suit your needs.
        </p>
      </div>

      {/* Courses Overview */}
      <div className="courses-overview">
        <h2>Our Courses</h2>
        <ul>
          <li>Beginner Course: Perfect for those new to micro-soldering</li>
          <li>Intermediate Course: Build upon your existing repair skills</li>
          <li>
            Masters Course: Master advanced board repair and troubleshooting
          </li>
        </ul>

        <p>
          <br />
          <Link className="apply-now-home-link" to="/apply-now">
            Apply Now
          </Link>{" "}
          <br />
          <span>to start your journey with us!</span>
        </p>
      </div>
    </div>
  );
};

export default Homepage;
