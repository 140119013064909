import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./About.css"; // Import the CSS file
import { Link } from "react-router-dom";

const About: React.FC = () => {
  // Updated message string with courses page overview information
  const message = `Welcome to Golden Craft Academy’s Courses Page!
Our intensive eight‑week micro‑soldering course is designed for both beginners and experienced technicians. Focusing on interposer board repairs and straight board repairs for iPhone models 7 through 16 Pro Max, our curriculum tackles issues such as power faults, charging problems, panic errors, audio faults, iTunes errors, network‑related challenges, and more. A mandatory two‑week internship at our mastercraft workshop ensures hands‑on experience before graduation, and discounted tool sales help you kickstart your career. Comprehensive video guides for all modules remain accessible even after training.`;

  // Immediately display the full message
  const [displayedMessage] = useState(message);
  const [Q1_isExpanded, setQ1_IsExpanded] = useState(false);
  const [Q2_isExpanded, setQ2_IsExpanded] = useState(false);
  const [Q3_isExpanded, setQ3_IsExpanded] = useState(false);
  const [Q4_isExpanded, setQ4_IsExpanded] = useState(false);
  const [Q5_isExpanded, setQ5_IsExpanded] = useState(false);
  const [Q6_isExpanded, setQ6_IsExpanded] = useState(false);
  const [Q7_isExpanded, setQ7_IsExpanded] = useState(false);
  const [Q8_isExpanded, setQ8_IsExpanded] = useState(false);
  const [Q9_isExpanded, setQ9_IsExpanded] = useState(false);
  const [Q10_isExpanded, setQ10_IsExpanded] = useState(false);
  const [aboutUsClass, setAboutUsClass] = useState("aboutUs-div");

  // Download form function
  const downloadForm = (): void => {
    const cvFile = process.env.PUBLIC_URL + "/Form.pdf";
    fetch(cvFile)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "Form.pdf", { type: "application/pdf" });
        saveAs(file);
      })
      .catch((err) => console.error("Error downloading form: ", err));
  };

  // Run once on mount to change the aboutUs class
  useEffect(() => {
    setTimeout(() => {
      setAboutUsClass("aboutUs-div-2");
    }, 0);
  }, []);

  return (
    <div className="AboutUs-body">
      <h1 className="component-heading">About Us</h1>
      {/* Carousel Section */}
      <div className="aboutUs-carousel">
        <Carousel controls={false} indicators={false} fade interval={3000}>
          <Carousel.Item>
            <img
              className="d-block carousel-Image"
              src={process.env.PUBLIC_URL + "/first.jpg"}
              alt="Class room front view"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block carousel-Image"
              src={process.env.PUBLIC_URL + "/second.jpg"}
              alt="Class room side view"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block carousel-Image"
              src={process.env.PUBLIC_URL + "/third.jpg"}
              alt="Panel one"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block carousel-Image"
              src={process.env.PUBLIC_URL + "/fourth.jpg"}
              alt="Panel two"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block carousel-Image"
              src={process.env.PUBLIC_URL + "/fifth.jpg"}
              alt="Panel three"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      {/* About Us Section */}
      <div className={aboutUsClass}>
        <div id="aboutUs">
          <h2 className="aboutUs-title">Golden Craft Academy</h2>
          <div id="d1">{displayedMessage}</div>
        </div>
      </div>

      {/* FAQ Section */}
      <div>
        <h1 className="faq-title">Frequently Asked Questions</h1>
        <div className="faq">
          {/* FAQ 1 */}
          <div
            className="faq-question first-child"
            onClick={() => setQ1_IsExpanded(!Q1_isExpanded)}
          >
            WHAT COURSES DO YOU OFFER?
            <span className={`faq-icon ${Q1_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q1_isExpanded && (
            <div className="faq-answer">
              Our academy offers an intensive eight‑week micro‑soldering course
              for both beginners and experienced technicians. The curriculum
              covers interposer board repairs and straight board repairs for
              iPhone models 7 through 16 Pro Max. We also address power faults,
              charging issues, panic errors, audio faults, iTunes errors,
              network‑related problems, and more. A mandatory two‑week
              internship at our mastercraft workshop ensures practical, hands‑on
              experience.
            </div>
          )}

          {/* FAQ 2 */}
          <div
            className="faq-question"
            onClick={() => setQ2_IsExpanded(!Q2_isExpanded)}
          >
            WHERE DO YOU OFFER THE TRAINING?
            <span className={`faq-icon ${Q2_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q2_isExpanded && (
            <div className="faq-answer">
              90A, Old Ota Road, Unity Bus Stop, Captain Area, Ekoro, Oke-odo
              LCDA, Alimosho, Lagos, Nigeria.
            </div>
          )}

          {/* FAQ 3 */}
          <div
            className="faq-question"
            onClick={() => setQ3_IsExpanded(!Q3_isExpanded)}
          >
            HOW MUCH DOES THE TRAINING COST?
            <span className={`faq-icon ${Q3_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q3_isExpanded && (
            <div className="faq-answer">
              The total tuition fee for the course, including the internship, is
              ₦600,000. This fee covers the complete training along with access
              to comprehensive video guides that remain available post-training.
            </div>
          )}

          {/* FAQ 4 */}
          <div
            className="faq-question"
            onClick={() => setQ4_IsExpanded(!Q4_isExpanded)}
          >
            HOW CAN I REGISTER?
            <span className={`faq-icon ${Q4_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q4_isExpanded && (
            <div className="faq-answer">
              Enrollment is exclusively available to technicians with prior
              repair experience. Prospective students must undergo a virtual
              screening process and answer detailed questions regarding their
              technical proficiency. You can register via our{" "}
              <Link to="/apply-now">Apply Now</Link> page or schedule a call
              with our account manager.
            </div>
          )}

          {/* FAQ 5 */}
          <div
            className="faq-question"
            onClick={() => setQ5_IsExpanded(!Q5_isExpanded)}
          >
            WHAT ARE THE REGISTRATION REQUIREMENTS?
            <span className={`faq-icon ${Q5_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q5_isExpanded && (
            <div className="faq-answer">
              Please complete the registration form with the following
              information:
              <br />
              <strong>Apprentice Details:</strong>
              <br />
              • Surname and Other Names
              <br />
              • Residential Address
              <br />
              • Sex, Marital Status, and Religion
              <br />
              • State of Origin, L.G.A., and Home Town
              <br />
              • Duration of Apprenticeship (Years/Months)
              <br />
              <br />
              <strong>Guarantor Details:</strong>
              <br />
              • Surname and Other Names
              <br />
              • Residential and Office Addresses
              <br />
              • Occupation and State of Origin
              <br />
              • Date of Birth and Relationship to the Apprentice
              <br />
              • Email Address and Phone Number
              <br />
              <br />
              <strong>Additional Requirements:</strong>
              <br />
              • Attach a passport photograph
              <br />
              • Ensure all details are accurate, as they are legally binding.
              <br />
              <br />
              <em>
                Golden Crafts Academy (RC: 3318076) – Expert in Hardware and
                Software of All Kinds of Phones
              </em>
              <br />
              90A, Old Ota Road, Captain Bus Stop, Captain Area, Oke-odo LGA,
              Alimosho, Lagos.
              <br />
              E‑mail:{" "}
              <a href="mailto:Goldencraftsacademyltd@gmail.com">
                Goldencraftsacademyltd@gmail.com
              </a>
              <br />
              Tel: <a href="tel:+2349132006777">+234 9132006 777</a>
            </div>
          )}

          {/* FAQ 6 */}
          <div
            className="faq-question"
            onClick={() => setQ6_IsExpanded(!Q6_isExpanded)}
          >
            WHAT IS THE MINIMUM AGE REQUIRED TO TAKE THE COURSE?
            <span className={`faq-icon ${Q6_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q6_isExpanded && (
            <div className="faq-answer">
              The minimum age requirement to enroll is 18 years.
            </div>
          )}

          {/* FAQ 7 */}
          <div
            className="faq-question"
            onClick={() => setQ7_IsExpanded(!Q7_isExpanded)}
          >
            HOW LONG IS THE PROGRAM?
            <span className={`faq-icon ${Q7_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q7_isExpanded && (
            <div className="faq-answer">
              The program lasts for six weeks, followed by a two-week
              internship. While two weeks is the standard duration, a one-week
              internship is also an option if you’re unable to stay for the full
              period. If you’d like to extend your internship beyond two weeks,
              special arrangements can be made.
            </div>
          )}

          {/* FAQ 8 */}
          <div
            className="faq-question"
            onClick={() => setQ8_IsExpanded(!Q8_isExpanded)}
          >
            IS 8 WEEKS ENOUGH TIME TO LEARN EVERYTHING?
            <span className={`faq-icon ${Q8_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q8_isExpanded && (
            <div className="faq-answer">
              Yes! Our eight‑week course, including a two‑week internship, is
              fully hands‑on. You’ll learn key skills such as iTunes and panic
              error troubleshooting, data recovery techniques, memory upgrade
              procedures, FPC replacement, running jumper with fly line wire,
              understanding ZXW (Zillion X works) and JCID, as well as
              troubleshooting and fault finding. This ensures you’re fully
              equipped to handle repairs independently.
            </div>
          )}

          {/* FAQ 9 */}
          <div
            className="faq-question"
            onClick={() => setQ9_IsExpanded(!Q9_isExpanded)}
          >
            WHAT BENEFITS DO I RECEIVE UPON COMPLETION OF THE COURSE?
            <span className={`faq-icon ${Q9_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q9_isExpanded && (
            <div className="faq-answer">
              After completing the training, you will receive a certificate of
              completion along with ongoing post‑course technical support. In
              addition, you’ll have mastered essential micro‑soldering skills
              and retain access to all comprehensive video guides for review.
            </div>
          )}

          {/* FAQ 10 */}
          <div
            className="faq-question"
            onClick={() => setQ10_IsExpanded(!Q10_isExpanded)}
          >
            HOW CAN I CONTACT YOU?
            <span className={`faq-icon ${Q10_isExpanded ? "expanded" : ""}`}>
              &#x25BC;
            </span>
          </div>
          {Q10_isExpanded && (
            <div className="faq-answer">
              You can contact us via phone, WhatsApp, or email.
              <br />
              Email:{" "}
              <a
                className="golden-email"
                href="mailto:goldencraftsacademyltd@gmail.com"
              >
                goldencraftsacademyltd@gmail.com
              </a>
              <br />
              Tel:{" "}
              <a className="tel" href="tel:+2349132006777">
                +234 9132006 777
              </a>
              <br />
              WhatsApp:{" "}
              <a className="whatsApp" href="https://wa.me/234912008777">
                +234 912008 777
              </a>
              <br />
              Follow us on Twitter, Facebook, and Instagram.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
