import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./GeneralStyle/generalStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Homepage from "./Homepage/Homepage";
import About from "./About/About";
import Footer from "./Footer/Footer";
import Contact from "./Contact/Contact";
import Courses from "./Courses/Courses";
import ApplyNow from "./ChatBox/ChatBox";
import TermsAndConditions from "./condition_privacy/TermsAndConditions";
import PrivacyPolicy from "./condition_privacy/PrivacyPolicy";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import NavigationBar from "./NavBar/NavigationBar";
import Information from "./Information/Information";

const App: React.FC = () => {
  // const [loading, setLoading] = useState<boolean>(true);

  // if (loading) return <div className="loading2"></div>;

  return (
    <div className="App app-container">
      <header>
        <NavigationBar />
      </header>
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/apply-now" element={<ApplyNow />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/Information" element={<Information />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
