import React, { useState, useEffect } from "react";
import "./Information.css";

import { Link } from "react-router-dom";

const Information: React.FC = () => {
  // About section message string
  const message = `
Our intensive eight‑week micro‑soldering course is designed for both beginners and experienced technicians. Focusing on interposer board repairs and straight board repairs for iPhone models 7 through 16 Pro Max, our curriculum tackles issues such as power faults, charging problems, panic errors, audio faults, iTunes errors, network‑related challenges, and more. A mandatory two‑week internship at our mastercraft workshop ensures hands‑on experience before graduation, and discounted tool sales help you kickstart your career. Comprehensive video guides for all modules remain accessible even after training.`;

  // State for FAQ expansion (10 FAQ items)
  const [Q1_isExpanded, setQ1_IsExpanded] = useState(false);
  const [Q2_isExpanded, setQ2_IsExpanded] = useState(false);
  const [Q3_isExpanded, setQ3_IsExpanded] = useState(false);
  const [Q4_isExpanded, setQ4_IsExpanded] = useState(false);
  const [Q5_isExpanded, setQ5_IsExpanded] = useState(false);
  const [Q6_isExpanded, setQ6_IsExpanded] = useState(false);
  const [Q7_isExpanded, setQ7_IsExpanded] = useState(false);
  const [Q8_isExpanded, setQ8_IsExpanded] = useState(false);
  const [Q9_isExpanded, setQ9_IsExpanded] = useState(false);
  const [Q10_isExpanded, setQ10_IsExpanded] = useState(false);
  const [aboutUsClass, setAboutUsClass] = useState("Extra-info-aboutUs-div");

  // Change the About section class on mount (for a transition effect)
  useEffect(() => {
    setTimeout(() => {
      setAboutUsClass("Extra-info-aboutUs-div-2");
    }, 0);
  }, []);

  return (
    <div className="Extra-info-Information-body">
      {/* About Section */}
      <div className="Extra-info-AboutUs-body">
        {/* About Us Content */}
        <div className={aboutUsClass}>
          <div id="aboutUs">
            <div id="d1">{message}</div>
          </div>
        </div>
      </div>

      {/* Courses Section */}
      <div className="container Extra-info-coursesContainer" id="courses">
        <div className="Extra-info-academy-overview">
          <h2>Academy Overview</h2>
          <p>
            The Academy offers an intensive eight‑week course covering critical
            topics in micro‑soldering, designed for both beginners and
            experienced technicians. Our curriculum focuses primarily on
            interposer board repairs, as well as straight board repairs for
            iPhone models 7 through 16 Pro Max. We address common issues such as
            power faults, charging issues, panic errors, audio faults, iTunes
            errors, network‑related problems and many more.
          </p>
          <p>
            Every student is required to complete a two‑week internship at our
            mastercraft workshop, providing hands‑on experience before
            graduation. This brings the total program duration to eight weeks.
            Additionally, we offer discounted tool sales to help you start your
            career immediately.
          </p>
          <p>
            Comprehensive video guides are provided for all modules and remain
            accessible after training. The total tuition fee for the course plus
            internship is &#8358;600,000.
          </p>
          <p>
            For interstate students, affordable accommodation is available,
            including 24‑hour power, room cleaning, and laundry services
            (laundry for personal clothing is not included).
          </p>
          <p>
            Enrollment is exclusively available to technicians with prior repair
            experience. Prospective students must undergo a virtual screening
            process and answer detailed questions regarding their experience and
            technical proficiency.
          </p>
        </div>

        <div className="Extra-info-course-summary-details-container">
          <div className="Extra-info-row">
            <h5 className="Extra-info-courseTitle">Course Summary</h5>
            <div className="Extra-info-beginners">
              <div className="Extra-info-courseHeadings">
                <h5>Cost: &#8358;600,000</h5>
                <h5>Duration: 8 weeks total (2 weeks internship)</h5>
                <h5>Phone-type: iPhone 7&#8209;16 pro max</h5>
                <h5 className="Extra-info-duration">
                  Time: Monday - Friday (10:00 AM - 3:00 PM)
                </h5>
              </div>
              <ul className="Extra-info-ulParent">
                <li>
                  Upon completion of the training, you'll have mastered the
                  following skills:
                </li>
                <ul>
                  <li>iTunes error troubleshooting</li>
                  <li>Panic error troubleshooting</li>
                  <li>Data recovery techniques</li>
                  <li>Memory upgrade procedures</li>
                  <li>FPC replacement</li>
                  <li>Running jumper with fly line wire</li>
                  <li>Understanding ZXW (Zillion X works), JCID</li>
                  <li>
                    Identification and function analysis of all ICs on the
                    motherboard
                  </li>
                  <li>Troubleshooting and fault finding</li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div>
        <h1 className="Extra-info-faq-title">Frequently Asked Questions</h1>
        <div className="Extra-info-faq">
          {/* FAQ 1 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ1_IsExpanded(!Q1_isExpanded)}
          >
            WHAT COURSES DO YOU OFFER?
            <span
              className={`Extra-info-faq-icon ${
                Q1_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q1_isExpanded && (
            <div className="Extra-info-faq-answer">
              Our academy offers an intensive eight‑week micro‑soldering course
              for both beginners and experienced technicians. The curriculum
              covers interposer board repairs and straight board repairs for
              iPhone models 7 through 16 Pro Max. We also address power faults,
              charging issues, panic errors, audio faults, iTunes errors,
              network‑related problems, and more. A mandatory two‑week
              internship at our mastercraft workshop ensures practical, hands‑on
              experience.
            </div>
          )}

          {/* FAQ 2 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ2_IsExpanded(!Q2_isExpanded)}
          >
            WHERE DO YOU OFFER THE TRAINING?
            <span
              className={`Extra-info-faq-icon ${
                Q2_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q2_isExpanded && (
            <div className="Extra-info-faq-answer">
              90A, Old Ota Road, Unity Bus Stop, Captain Area, Ekoro, Oke-odo
              LCDA, Alimosho, Lagos, Nigeria.
            </div>
          )}

          {/* FAQ 3 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ3_IsExpanded(!Q3_isExpanded)}
          >
            HOW MUCH DOES THE TRAINING COST?
            <span
              className={`Extra-info-faq-icon ${
                Q3_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q3_isExpanded && (
            <div className="Extra-info-faq-answer">
              The total tuition fee for the course, including the internship, is
              ₦600,000. This fee covers the complete training along with access
              to comprehensive video guides that remain available post-training.
            </div>
          )}

          {/* FAQ 4 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ4_IsExpanded(!Q4_isExpanded)}
          >
            HOW CAN I REGISTER?
            <span
              className={`Extra-info-faq-icon ${
                Q4_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q4_isExpanded && (
            <div className="Extra-info-faq-answer">
              Enrollment is exclusively available to technicians with prior
              repair experience. Prospective students must undergo a virtual
              screening process and answer detailed questions regarding their
              technical proficiency. You can register via our{" "}
              <Link to="/apply-now">Apply Now</Link> page or schedule a call
              with our account manager.
            </div>
          )}

          {/* FAQ 5 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ5_IsExpanded(!Q5_isExpanded)}
          >
            WHAT ARE THE REGISTRATION REQUIREMENTS?
            <span
              className={`Extra-info-faq-icon ${
                Q5_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q5_isExpanded && (
            <div className="Extra-info-faq-answer">
              Please complete the registration form with the following
              information:
              <br />
              <strong>Apprentice Details:</strong>
              <br />
              • Surname and Other Names
              <br />
              • Residential Address
              <br />
              • Sex, Marital Status, and Religion
              <br />
              • State of Origin, L.G.A., and Home Town
              <br />
              • Duration of Apprenticeship (Years/Months)
              <br />
              <br />
              <strong>Guarantor Details:</strong>
              <br />
              • Surname and Other Names
              <br />
              • Residential and Office Addresses
              <br />
              • Occupation and State of Origin
              <br />
              • Date of Birth and Relationship to the Apprentice
              <br />
              • Email Address and Phone Number
              <br />
              <br />
              <strong>Additional Requirements:</strong>
              <br />
              • Attach a passport photograph
              <br />
              • Ensure all details are accurate, as they are legally binding.
              <br />
              <br />
              <em>
                Golden Crafts Academy (RC: 3318076) – Expert in Hardware and
                Software of All Kinds of Phones
              </em>
              <br />
              90A, Old Ota Road, Captain Bus Stop, Captain Area, Oke-odo LGA,
              Alimosho, Lagos.
              <br />
              E‑mail:{" "}
              <a href="mailto:Goldencraftsacademyltd@gmail.com">
                Goldencraftsacademyltd@gmail.com
              </a>
              <br />
              Tel: <a href="tel:+2349132006777">+234 9132006 777</a>
            </div>
          )}

          {/* FAQ 6 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ6_IsExpanded(!Q6_isExpanded)}
          >
            WHAT IS THE MINIMUM AGE REQUIRED TO TAKE THE COURSE?
            <span
              className={`Extra-info-faq-icon ${
                Q6_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q6_isExpanded && (
            <div className="Extra-info-faq-answer">
              The minimum age requirement to enroll is 18 years.
            </div>
          )}

          {/* FAQ 7 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ7_IsExpanded(!Q7_isExpanded)}
          >
            HOW LONG IS THE PROGRAM?
            <span
              className={`Extra-info-faq-icon ${
                Q7_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q7_isExpanded && (
            <div className="Extra-info-faq-answer">
              The program lasts for six weeks, followed by a two‑week
              internship. While two weeks is the standard duration, a one‑week
              internship is also an option if you’re unable to stay for the full
              period. If you’d like to extend your internship beyond two weeks,
              special arrangements can be made.
            </div>
          )}

          {/* FAQ 8 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ8_IsExpanded(!Q8_isExpanded)}
          >
            IS 8 WEEKS ENOUGH TIME TO LEARN EVERYTHING?
            <span
              className={`Extra-info-faq-icon ${
                Q8_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q8_isExpanded && (
            <div className="Extra-info-faq-answer">
              Yes! Our eight‑week course, including a two‑week internship, is
              fully hands‑on. You’ll learn key skills such as iTunes and panic
              error troubleshooting, data recovery techniques, memory upgrade
              procedures, FPC replacement, running jumper with fly line wire,
              understanding ZXW (Zillion X works) and JCID, as well as
              troubleshooting and fault finding. This ensures you’re fully
              equipped to handle repairs independently.
            </div>
          )}

          {/* FAQ 9 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ9_IsExpanded(!Q9_isExpanded)}
          >
            WHAT BENEFITS DO I RECEIVE UPON COMPLETION OF THE COURSE?
            <span
              className={`Extra-info-faq-icon ${
                Q9_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q9_isExpanded && (
            <div className="Extra-info-faq-answer">
              After completing the training, you will receive a certificate of
              completion along with ongoing post‑course technical support. In
              addition, you’ll have mastered essential micro‑soldering skills
              and retain access to all comprehensive video guides for review.
            </div>
          )}

          {/* FAQ 10 */}
          <div
            className="Extra-info-faq-question"
            onClick={() => setQ10_IsExpanded(!Q10_isExpanded)}
          >
            HOW CAN I CONTACT YOU?
            <span
              className={`Extra-info-faq-icon ${
                Q10_isExpanded ? "Extra-info-expanded" : ""
              }`}
            >
              &#x25BC;
            </span>
          </div>
          {Q10_isExpanded && (
            <div className="Extra-info-faq-answer">
              You can contact us via phone, WhatsApp, or email.
              <br />
              Email:{" "}
              <a
                className="Extra-info-golden-email"
                href="mailto:goldencraftsacademyltd@gmail.com"
              >
                goldencraftsacademyltd@gmail.com
              </a>
              <br />
              Tel:{" "}
              <a className="Extra-info-tel" href="tel:+2349132006777">
                +234 9132006 777
              </a>
              <br />
              WhatsApp:{" "}
              <a
                className="Extra-info-whatsApp"
                href="https://wa.me/234912008777"
              >
                +234 912008 777
              </a>
              <br />
              Follow us on Twitter, Facebook, and Instagram.
            </div>
          )}
        </div>
      </div>

      <p className="Extra-info-information-apply-now-container">
        <br />
        <Link className="Extra-info-information-apply-now-link" to="/apply-now">
          Apply Now
        </Link>
        <br />
        <span>to start your journey with us!</span>
      </p>
    </div>
  );
};

export default Information;
