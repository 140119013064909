import React from "react";
import { Link } from "react-router-dom";
import "./PrivacyPolicy.css";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-container">
      <h2>Privacy Policy</h2>
      <p>
        At Golden Crafts Academy, we respect your privacy and are committed to
        protecting your personal information. This Privacy Policy explains how
        we collect, use, and safeguard your information when you use our website
        or enroll in our training programs.
      </p>
      <p>
        <strong>Information Collection:</strong> We collect personal data
        including your name, contact details, residential address, and other
        information provided in your registration form. This data is used solely
        for enrollment, administrative purposes, and to keep you informed about
        our courses and updates.
      </p>
      <p>
        <strong>Data Usage:</strong> Your information is processed securely and
        used for course administration, communication regarding your training,
        and other related purposes. We never sell or share your personal data
        unless the law requires it or you give us clear permission.
      </p>
      <p>
        <strong>Data Security:</strong> We use strong security measures to keep
        your data safe from unauthorized access or changes. However, no system
        is completely secure, so we cannot promise 100% protection.
      </p>
      <p>
        <strong>Your Rights:</strong> You have the right to access, correct, or
        request deletion of your personal information. For any data-related
        inquiries, please contact us at{" "}
        <a href="mailto:Goldencraftsacademyltd@gmail.com">
          Goldencraftsacademyltd@gmail.com
        </a>
        .
      </p>
      <p>
        By using our website, you consent to the collection and use of your
        information as described in this Privacy Policy.
      </p>
      <Link className="back-link" to="/download">
        Back
      </Link>
    </div>
  );
};

export default PrivacyPolicy;
