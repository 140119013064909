import React, { useState, ChangeEvent, FormEvent } from "react";
import * as FileSaver from "file-saver";
import { Link } from "react-router-dom";
import { db } from "../FireBaseConfig";
import { collection, addDoc } from "firebase/firestore";
import "./RegistrationForm.css";

const RegistrationForm: React.FC = () => {
  // Phone verification states
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);

  // Form component states
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);
  const [viewMode, setViewMode] = useState<"choice" | "online">("choice");
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Updated state to include new fields for Health and Accommodation
  const [formData, setFormData] = useState({
    apprenticeSurname: "",
    apprenticeOtherNames: "",
    apprenticeAddress: "",
    sex: "",
    maritalStatus: "",
    religion: "",
    stateOfOrigin: "",
    lga: "",
    homeTown: "",
    apprenticeshipDuration: "",
    // Health & Illness Information
    healthCondition: "", // "Yes" or "No"
    illnessNature: "",
    diagnosedDate: "",
    currentTreatment: "",
    specificNeeds: "",
    emergencyContact: "",
    // Accommodation Details
    onCampusAccommodation: "", // "Yes" or "No"
    accommodationType: "",
    accommodationDuration: "",
    accommodationPreferences: "",
    // Guarantor Details
    guarantorSurname: "",
    guarantorOtherNames: "",
    guarantorAddress: "",
    guarantorOfficeAddress: "",
    guarantorOccupation: "",
    guarantorStateOfOrigin: "",
    guarantorDOB: "",
    guarantorRelationship: "",
    guarantorEmail: "",
    guarantorPhone: "",
    // Signatures and photo
    traineeSignature: "",
    guarantorSignature: "",
    photo: null as File | null,
  });

  // Phone verification function updated to use Netlify function
  const handlePhoneVerification = async (e: FormEvent) => {
    e.preventDefault();
    if (!phoneNumber) {
      setVerificationError("Please enter your phone number.");
      return;
    }
    setIsVerifying(true);

    try {
      const res = await fetch("/.netlify/functions/verifyPhone", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phoneNumber }),
      });
      const result = await res.json();
      if (result.verified) {
        setIsVerified(true);
        setVerificationError("");
      } else {
        setVerificationError(
          "We couldn't verify your phone number. Please ensure you use the same number you provided during your interview."
        );
        setIsVerifying(false);
      }
    } catch (error) {
      console.error("Error verifying phone number: ", error);
      setVerificationError("An error occurred during verification.");
      setIsVerifying(false);
    }
  };

  // Handle download of PDF form
  const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!acceptedTerms || !acceptedPrivacy) {
      alert(
        "Please review and accept the Terms and Conditions and Privacy Policy before proceeding."
      );
      return;
    }
    const formFile = process.env.PUBLIC_URL + "/Form.pdf";
    fetch(formFile)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "Form.pdf", { type: "application/pdf" });
        FileSaver.saveAs(file);
      })
      .catch((err) => console.error("Error downloading form: ", err));
  };

  // Switch to online form view
  const handleFillOnline = () => {
    if (!acceptedTerms || !acceptedPrivacy) {
      alert(
        "Please review and accept the Terms and Conditions and Privacy Policy before proceeding."
      );
      return;
    }
    setViewMode("online");
  };

  // Update form input values
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle photo selection and preview (max 2MB)
  const handlePhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.startsWith("image/")) {
        alert("Please select a valid image file.");
        return;
      }
      if (file.size > 2 * 1024 * 1024) {
        alert("File size should be less than 2MB.");
        return;
      }
      setFormData((prev) => ({
        ...prev,
        photo: file,
      }));
      const reader = new FileReader();
      reader.onload = () => {
        setPhotoPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Submit the online registration form
  const handleOnlineSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Check if a photo has been uploaded
    if (!formData.photo) {
      alert(
        "Please upload a clear, recent passport-sized photograph (max 2MB) showing your full face; this will be used for your ID card."
      );
      return;
    }
    // Basic validation for apprentice
    if (
      !formData.apprenticeSurname ||
      !formData.apprenticeOtherNames ||
      !formData.apprenticeAddress ||
      !formData.sex ||
      !formData.maritalStatus ||
      !formData.religion ||
      !formData.stateOfOrigin ||
      !formData.lga ||
      !formData.homeTown ||
      !formData.apprenticeshipDuration ||
      !formData.traineeSignature
    ) {
      alert("Please fill all apprentice information.");
      return;
    }

    // If the user has a health condition, validate the extra fields
    if (formData.healthCondition === "Yes") {
      if (
        !formData.illnessNature ||
        !formData.diagnosedDate ||
        !formData.currentTreatment ||
        !formData.emergencyContact
      ) {
        alert("Please fill in all health condition details.");
        return;
      }
    }

    // If the user is interested in accommodation, validate those fields
    if (formData.onCampusAccommodation === "Yes") {
      if (
        !formData.accommodationType ||
        !formData.accommodationDuration ||
        !formData.accommodationPreferences
      ) {
        alert("Please fill in all accommodation details.");
        return;
      }
    }

    // Guarantor's validation for required fields
    if (
      !formData.guarantorSurname ||
      !formData.guarantorOtherNames ||
      !formData.guarantorAddress ||
      !formData.guarantorAddress ||
      !formData.guarantorOccupation ||
      !formData.guarantorStateOfOrigin ||
      !formData.guarantorDOB ||
      !formData.guarantorRelationship ||
      !formData.guarantorPhone ||
      !formData.guarantorSignature
    ) {
      alert("Please fill in all guarantor's information.");
      return;
    }

    setIsSubmitting(true);
    let photoURL = "";
    // Upload photo to Cloudinary if provided
    if (formData.photo) {
      const data = new FormData();
      data.append("file", formData.photo);
      data.append("upload_preset", "passport");
      try {
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/dkgfxdnc1/image/upload",
          {
            method: "POST",
            body: data,
          }
        );
        const result = await res.json();
        photoURL = result.secure_url;
      } catch (error) {
        console.error("Error uploading photo: ", error);
      }
    }

    // Prepare the registration data to store in Firestore
    const registrationData = {
      ...formData,
      photo: photoURL, // store the URL from Cloudinary instead of the File object
      submittedAt: new Date(),
    };

    try {
      await addDoc(collection(db, "Registrations"), registrationData);

      // Send email notification to the admin
      await fetch("/.netlify/functions/sendConversationEmailNotification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          apprenticeSurname: formData.apprenticeSurname,
          apprenticeOtherNames: formData?.apprenticeOtherNames,
        }),
      }).catch((error) => {
        // console.error("Error sending email notification: ", error);
      });

      alert("Registration submitted successfully!");
      setIsSubmitted(true);
      // Optionally, clear the form if desired:
      setFormData({
        apprenticeSurname: "",
        apprenticeOtherNames: "",
        apprenticeAddress: "",
        sex: "",
        maritalStatus: "",
        religion: "",
        stateOfOrigin: "",
        lga: "",
        homeTown: "",
        apprenticeshipDuration: "",
        healthCondition: "",
        illnessNature: "",
        diagnosedDate: "",
        currentTreatment: "",
        specificNeeds: "",
        emergencyContact: "",
        onCampusAccommodation: "",
        accommodationType: "",
        accommodationDuration: "",
        accommodationPreferences: "",
        guarantorSurname: "",
        guarantorOtherNames: "",
        guarantorAddress: "",
        guarantorOfficeAddress: "",
        guarantorOccupation: "",
        guarantorStateOfOrigin: "",
        guarantorDOB: "",
        guarantorRelationship: "",
        guarantorEmail: "",
        guarantorPhone: "",
        traineeSignature: "",
        guarantorSignature: "",
        photo: null,
      });
      setPhotoPreview(null);
    } catch (error) {
      console.error("Error submitting registration: ", error);
      alert("Failed to submit registration. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render phone verification page if not verified yet
  if (!isVerified) {
    return (
      <div>
        <p className="heading-verify-text">
          Please ensure you have permission to register before proceeding.
        </p>

        <div className="verify-container">
          <h2 className="verify-heading">Phone Verification</h2>
          <p className="verify-text">
            Enter the exact phone number you submited during your interview to
            proceed:
          </p>
          <form onSubmit={handlePhoneVerification} className="verify-form">
            <input
              type="tel"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              className="verify-input"
            />
            <button type="submit" className="verify-button">
              {isVerifying ? "Opening..." : "Open Form"}
            </button>
          </form>
          {verificationError && (
            <p className="verify-error">{verificationError}</p>
          )}
        </div>
      </div>
    );
  }

  // Render online form view
  if (viewMode === "online") {
    return (
      <div className="registration-form-container">
        <h2>Online Registration Form</h2>
        <h2>All Information Must Be Filled</h2>
        <button className="download-button" onClick={handleDownload}>
          Prefer to Download? Get the Form (PDF)
        </button>
        <form onSubmit={handleOnlineSubmit}>
          <h3>DETAILS OF APPRENTICE</h3>
          <div className="form-group">
            <label>Passport Photograph* (Max: 2MB):</label>
            <p className="registration-form-passport-instruction">
              Please upload a clear, recent passport-sized photograph showing
              your full face; this will be used for your ID card.
            </p>
            <input
              required
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
            />
            {photoPreview && (
              <img src={photoPreview} alt="Preview" className="photo-preview" />
            )}
          </div>
          <div className="form-group">
            <label>Surname*:</label>
            <input
              type="text"
              name="apprenticeSurname"
              value={formData.apprenticeSurname}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Other Names*:</label>
            <input
              type="text"
              name="apprenticeOtherNames"
              value={formData.apprenticeOtherNames}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Residential Address*:</label>
            <textarea
              required
              name="apprenticeAddress"
              value={formData.apprenticeAddress}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Sex*:</label>
            <select
              required
              name="sex"
              value={formData.sex}
              onChange={handleInputChange}
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label>Marital Status*:</label>
            <input
              required
              type="text"
              name="maritalStatus"
              value={formData.maritalStatus}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Religion*:</label>
            <input
              required
              type="text"
              name="religion"
              value={formData.religion}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>State of Origin*:</label>
            <input
              required
              type="text"
              name="stateOfOrigin"
              value={formData.stateOfOrigin}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>L.G.A.*:</label>
            <input
              required
              type="text"
              name="lga"
              value={formData.lga}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Home Town*:</label>
            <input
              required
              type="text"
              name="homeTown"
              value={formData.homeTown}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Years/Month of Apprenticeship*:</label>
            <input
              required
              type="text"
              name="apprenticeshipDuration"
              value={formData.apprenticeshipDuration}
              onChange={handleInputChange}
            />
          </div>

          {/* Health & Illness Information */}
          <h3>HEALTH & ILLNESS INFORMATION</h3>
          <div className="form-group">
            <label>
              Do you have any existing health conditions or illnesses?*
            </label>
            <select
              name="healthCondition"
              value={formData.healthCondition}
              onChange={handleInputChange}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          {formData.healthCondition === "Yes" && (
            <>
              <div className="form-group">
                <label>Nature of the Illness*:</label>
                <input
                  type="text"
                  name="illnessNature"
                  value={formData.illnessNature}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>When was it diagnosed?*:</label>
                <input
                  type="date"
                  name="diagnosedDate"
                  value={formData.diagnosedDate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Any current treatment or medication*:</label>
                <input
                  type="text"
                  name="currentTreatment"
                  value={formData.currentTreatment}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  Any specific needs or considerations during your training:
                </label>
                <input
                  type="text"
                  name="specificNeeds"
                  value={formData.specificNeeds}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Emergency Contact Phone Number*:</label>
                <input
                  type="tel"
                  name="emergencyContact"
                  value={formData.emergencyContact}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </>
          )}

          {/* Accommodation Details */}
          <h3>ACCOMMODATION DETAILS</h3>
          <div className="form-group">
            <label>Are you interested in on-campus accommodation?*</label>
            <select
              name="onCampusAccommodation"
              value={formData.onCampusAccommodation}
              onChange={handleInputChange}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          {formData.onCampusAccommodation === "Yes" && (
            <>
              <div className="form-group">
                <label>Preferred type (e.g., single bed/shared)*:</label>
                <input
                  type="text"
                  name="accommodationType"
                  value={formData.accommodationType}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Duration of stay (in months)*:</label>
                <input
                  type="text"
                  name="accommodationDuration"
                  value={formData.accommodationDuration}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Any specific requirements or preferences*:</label>
                <input
                  type="text"
                  name="accommodationPreferences"
                  value={formData.accommodationPreferences}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </>
          )}

          <h3>DETAILS OF GUARANTOR</h3>
          <div className="form-group">
            <label>Surname*:</label>
            <input
              type="text"
              name="guarantorSurname"
              value={formData.guarantorSurname}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Other Names*:</label>
            <input
              type="text"
              name="guarantorOtherNames"
              value={formData.guarantorOtherNames}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Residential Address*:</label>
            <textarea
              required
              name="guarantorAddress"
              value={formData.guarantorAddress}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Office Address*:</label>
            <textarea
              required
              name="guarantorOfficeAddress"
              value={formData.guarantorOfficeAddress}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Occupation*:</label>
            <input
              required
              type="text"
              name="guarantorOccupation"
              value={formData.guarantorOccupation}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>State of Origin*:</label>
            <input
              required
              type="text"
              name="guarantorStateOfOrigin"
              value={formData.guarantorStateOfOrigin}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Date of Birth*:</label>
            <input
              required
              type="date"
              name="guarantorDOB"
              value={formData.guarantorDOB}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Relationship*:</label>
            <input
              required
              type="text"
              name="guarantorRelationship"
              value={formData.guarantorRelationship}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>E-mail Address*:</label>
            <input
              type="email"
              name="guarantorEmail"
              value={formData.guarantorEmail}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Phone No.*:</label>
            <input
              required
              type="tel"
              name="guarantorPhone"
              value={formData.guarantorPhone}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Trainee Signature* (Enter full name):</label>
            <input
              type="text"
              name="traineeSignature"
              value={formData.traineeSignature}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Guarantor Signature* (Enter full name):</label>
            <input
              type="text"
              name="guarantorSignature"
              value={formData.guarantorSignature}
              onChange={handleInputChange}
              required
            />
          </div>
          <button
            className="submit-registration-button"
            type="submit"
            disabled={isSubmitting || isSubmitted}
          >
            {isSubmitting
              ? "Submitting..."
              : isSubmitted
                ? "Submitted"
                : "Submit Registration"}
          </button>
        </form>
      </div>
    );
  }

  // Render the initial choice view
  return (
    <div className="registration-choice-container">
      <h2>Registration Form</h2>
      <p>
        To enroll, please review and accept our{" "}
        <strong>Terms and Conditions</strong> and{" "}
        <strong>Privacy Policy</strong>. Then, choose to either fill the
        registration form online or download it.
        <br />
        Once completed, kindly attach and send the form to{" "}
        <a href="mailto:Goldencraftsacademyltd@gmail.com">
          Goldencraftsacademyltd@gmail.com
        </a>
        . Thank you!
      </p>
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            checked={acceptedTerms}
            onChange={(e) => setAcceptedTerms(e.target.checked)}
          />
          I have read and accept the{" "}
          <Link to="/terms-and-conditions">Terms and Conditions</Link>.
        </label>
      </div>
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            checked={acceptedPrivacy}
            onChange={(e) => setAcceptedPrivacy(e.target.checked)}
          />
          I have read and accept the{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </label>
      </div>
      <div className="button-group">
        <button className="download-button" onClick={handleDownload}>
          Download Registration Form
        </button>
        <button className="fill-online-button" onClick={handleFillOnline}>
          Fill Registration Form Online
        </button>
      </div>
    </div>
  );
};

export default RegistrationForm;
