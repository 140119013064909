import { EnumType } from "typescript";

class ValidationService {
  validateNigerianPhoneNumber(phoneNumber: string): boolean {
    if (!phoneNumber || phoneNumber === "none") {
      return false;
    }
    const trimmed = phoneNumber.trim();
    // Valid formats:
    // Format 1: +234 followed by 10 digits (e.g., +2347031635101)
    // Format 2: 0 followed by 10 digits (e.g., 07031635101)
    // Format 3: +2340 followed by 10 digits (e.g., +23407031635101)
    const format1 = /^\+234\d{10}$/;
    const format2 = /^0\d{10}$/;
    const format3 = /^\+2340\d{10}$/;
    return (
      format1.test(trimmed) || format2.test(trimmed) || format3.test(trimmed)
    );
  }
}
export default ValidationService;
