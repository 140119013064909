import React from "react";
import {
  DocumentData,
  updateDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../FireBaseConfig";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "./NavBar.css";

const NavigationBar: React.FC = () => {
  return (
    <Navbar
      className="navigation-bar-container"
      expand="lg"
      variant="dark"
      fixed="top"
    >
      <Container>
        {/* Logo / Brand */}
        <Navbar.Brand as={Link} to="/home">
          <img
            className="brand-logo"
            src={process.env.PUBLIC_URL + "/logo4.png"}
            alt="Company Logo"
          />
        </Navbar.Brand>

        {/* Hamburger Menu Toggler */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Collapsible Content */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about-us">
              About Us
            </Nav.Link>
            <Nav.Link as={Link} to="/courses">
              Courses
            </Nav.Link>
            <Nav.Link as={Link} to="/contact-us">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
