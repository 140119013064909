import React from "react";
import "./TermsAndConditions.css";
import { Link } from "react-router-dom";

const TermsAndConditions: React.FC = () => {
  return (
    <div className="terms-container">
      <h2>Terms and Conditions</h2>
      <p>
        Welcome to Golden Crafts Academy. By accessing and using this website,
        you agree to comply with and be bound by the following Terms and
        Conditions. If you do not agree to these terms, please refrain from
        using our website or enrolling in our training programs.
      </p>
      <p>
        <strong>1. Intellectual Property & Training Materials</strong> &#8211;
        All content, including training videos, tutorials, images, and documents
        available on this website, are the exclusive property of Golden Crafts
        Academy. The training videos and other materials are provided solely for
        your personal educational use. Downloading, screen recording, or
        redistributing these materials without our prior written consent is
        strictly prohibited and may result in legal action.
      </p>
      <p>
        <strong>2. Enrollment and Course Conduct</strong> &#8211; All students
        enrolling in our courses must adhere to our guidelines. This includes
        attending classes punctually, abstaining from unauthorized business
        transactions during training, and maintaining full focus on the course.
        Any deviation from these guidelines may result in disciplinary action,
        including expulsion from the Academy, with no refund of fees.
      </p>
      <p>
        <strong>3. Accommodation & Security</strong> &#8211; For students
        residing in our accommodation, you are solely responsible for
        safeguarding your personal belongings. In addition, while using Academy
        facilities, you are responsible for protecting all property &#8211; both
        yours and that of the Academy. The Academy will not be liable for any
        loss, theft, or damage. Both students and their guarantors agree to
        assume full responsibility for security-related matters.
      </p>
      <p>
        <strong>4. Student Conduct & Discipline</strong> &#8211; A respectful
        and safe environment is essential. Any form of fighting, violent
        behavior, theft, or disruptive conduct during classes or while in
        Academy accommodation is strictly forbidden. The Academy reserves the
        right to take immediate disciplinary or legal action against any student
        who breaches these rules.
      </p>
      <p>
        <strong>5. Liability & Indemnity</strong> &#8211; By enrolling, you and
        your guarantor agree to indemnify and hold harmless Golden Crafts
        Academy, its affiliates, and its staff from any claims or losses arising
        from any breach of these Terms and Conditions or any negligent or
        intentional acts by you.
      </p>
      <p>
        <strong>6. Amendments</strong> &#8211; Golden Crafts Academy reserves
        the right to update or modify these Terms and Conditions at any time
        without prior notice. Your continued use of the website or enrollment in
        our programs signifies your acceptance of any changes.
      </p>
      <p>
        By using our website or enrolling in our courses, you acknowledge that
        you have read, understood, and agree to be bound by these Terms and
        Conditions.
      </p>
      <Link className="back-link" to="/download">
        Back
      </Link>
    </div>
  );
};

export default TermsAndConditions;
