import React, { useState, useEffect } from "react";
import "./ChatBox.css";
import ValidationService from "../Services/ValidationService";

interface Message {
  type: "question" | "answer";
  text: string;
}

const questions: string[] = [
  "How long have you been involved in the phone repair field or industry?",
  "What repair tasks can you perform comfortably without guidance? (For example, how do you measure your competency with your current skills?)",
  "Are you comfortable using a rework station and a soldering station (i.e., soldering iron) without damaging the phone? Please describe the tasks you can perform using these tools.",
  "Can you confidently assemble and disassemble phones?",
  "Are you capable of removing the screen from iPhone X to iPhone 16 Pro Max without causing damage?",
  "What is your full name?",
  "What is your phone number?",
  "What is your email address? (Type NA if you don't have)",
];

const ChatBox: React.FC = () => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [responses, setResponses] = useState<string[]>([]);
  const [currentInput, setCurrentInput] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [conversation, setConversation] = useState<Message[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const validation = new ValidationService();

  // On mount, display only the first question (the introduction is separate)
  useEffect(() => {
    setConversation([{ type: "question", text: questions[0] }]);
  }, []);

  // Handle submission of a single answer
  const handleAnswerSubmit = () => {
    if (currentInput.trim() === "") return;
    const answer = currentInput.trim();

    // If the current question is "What is your phone number?" validate it.
    if (questions[questionIndex].toLowerCase().includes("phone number")) {
      const isValid = validation.validateNigerianPhoneNumber(answer);
      if (!isValid) {
        alert(
          "Invalid phone number. Please enter a valid Nigerian phone number."
        );
        return;
      }
    }

    setResponses((prev) => [...prev, answer]);
    setConversation((prev) => [...prev, { type: "answer", text: answer }]);
    setCurrentInput("");
    // Show next question if available
    if (questionIndex < questions.length - 1) {
      setTimeout(() => {
        const nextIndex = questionIndex + 1;
        setQuestionIndex(nextIndex);
        setConversation((prev) => [
          ...prev,
          { type: "question", text: questions[nextIndex] },
        ]);
      }, 1000);
    }
  };

  // Handle final submission of all responses by sending data to Netlify function
  const handleSubmitAll = async () => {
    if (responses.length < questions.length) {
      alert("Please answer all questions before submitting.");
      return;
    }
    setSubmitting(true);

    // Start extraction of phone number and full name
    const extractField = (substring: string, conv: Message[]): string => {
      for (let i = 0; i < conv.length; i++) {
        if (
          conv[i].type === "question" &&
          conv[i].text.toLowerCase().includes(substring.toLowerCase())
        ) {
          if (conv[i + 1] && conv[i + 1].type === "answer") {
            return conv[i + 1].text;
          }
        }
      }
      return "";
    };
    const email = extractField("email address", conversation);
    const phoneNumber = extractField("phone number", conversation);
    const fullName = extractField("full name", conversation);
    // End extraction of phone number and full name

    if (!phoneNumber) {
      alert("Phone number is missing from your responses.");
      setSubmitting(false);
      return;
    }
    if (!fullName) {
      alert("Full name is missing from your responses.");
      setSubmitting(false);
      return;
    }
    // End extraction of phone number and full name

    try {
      const response = await fetch("/.netlify/functions/sendConversation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber, fullName, email, conversation }),
      });

      if (response.status === 409) {
        alert("You have already submitted a response.");
        setSubmitting(false);
        return;
      }

      if (!response.ok) {
        alert("Interview submission failed. Please try again later.");
        throw new Error("Failed to send conversation");
      }

      alert(
        `Your responses have been submitted successfully! If you qualify, we will reach out to you via ${phoneNumber} or email if provided.`
      );
      setSubmitted(true);
    } catch (error) {
      alert("There was an error submitting your responses. Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <div className="chatbox-container">
      <div className="chatbox-header">
        <h2 className="chatbox-header-title">Eligibility Questionnaire</h2>
        <p className="chatbox-intro">
          Every interested student must answer the following questions to
          determine eligibility for training. Language is not a barrier. Please
          answer honestly and without exaggeration so that the academy can
          assess where additional support may be needed.
        </p>

        <p className="chatbox-voice-note">
          <a
            href="https://wa.me/234912008777"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to respond with a voice note
          </a>
        </p>
      </div>
      <div
        className="chatbox-conversation"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/whats.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {conversation.map((msg, index) => (
          <div
            key={index}
            className={`chatbox-message ${
              msg.type === "question" ? "chatbox-question" : "chatbox-answer"
            }`}
          >
            {msg.text}
          </div>
        ))}
      </div>
      {questionIndex < questions.length &&
        responses.length !== questions.length && (
          <div className="chatbox-input-container">
            <input
              type="text"
              className="chatbox-input"
              value={currentInput}
              onChange={(e) => setCurrentInput(e.target.value)}
              placeholder="Type your answer here..."
            />

            <button className="chatbox-button" onClick={handleAnswerSubmit}>
              {/* WhatsApp Icon as SVG */}
              <svg
                height="48"
                viewBox="0 0 48 48"
                width="48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.02 42l41.98-18-41.98-18-.02 14 30 4-30 4z" />
                <path d="M0 0h48v48h-48z" fill="none" />
              </svg>
            </button>
          </div>
        )}
      {responses.length === questions.length && (
        <div className="chatbox-final-submit">
          <p className="chatbox-thank-you">Thank you!</p>
          <button
            className="chatbox-submit-button"
            onClick={handleSubmitAll}
            disabled={submitting || submitted}
          >
            {submitting ? "Submitting..." : "Submit Responses"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
