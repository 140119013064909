import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // Import the CSS file

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/courses">Courses</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/apply-now">Apply Now</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Contact Us</h4>
          <p>
            <strong>Tel:</strong> +234 9132006 777
          </p>
          <p>
            <strong>WhatsApp:</strong> +234 912008 777
          </p>
          <p>
            <strong>Email:</strong> goldencraftsacademyltd@gmail.com
          </p>
          <p>
            <strong>Address:</strong> 90A, Old Ota Road, Unity Bus Stop, Captain
            Area, Ekoro, Oke-odo LCDA, Alimosho, Lagos
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2025 Golden Craft Academy. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
